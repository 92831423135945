import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  // **Authentication State**
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [passwordInput, setPasswordInput] = useState('');
  const [authError, setAuthError] = useState('');

  // **Secret Key (Password)**
  const SECRET_KEY = 'P@rKaDo'; // Secret key as per your requirement

  // **Booking Management State**
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sendingEmailId, setSendingEmailId] = useState(null);
  const [activeTab, setActiveTab] = useState('calendar');
  const [newBooking, setNewBooking] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    carPlate: '',
    startDate: null,
    endDate: null,
    totalPrice: '',
    status: 'Pending'
  });

  useEffect(() => {
    if (isAuthenticated) {
      fetchBookings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const fetchBookings = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings');
      setBookings(response.data);
    } catch (error) {
      console.error('Error fetching bookings:', error);
      setError('Failed to fetch bookings. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateBooking = async (e) => {
    e.preventDefault();
    try {
      // Create the new booking
      const response = await axios.post('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings', newBooking);
      toast.success('Booking created successfully!');

      // Extract the newly created booking's ID and email
      const bookingId = response.data.id; // Adjust this if your API returns the ID differently
      const email = newBooking.email;

      // Prepare the email request payload
      const emailRequest = {
        email: email,
        bookingId: bookingId,
      };

      // Send the reservation email
      try {
        await axios.post('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/send-reservation-email', emailRequest);
        toast.success('Reservation email sent successfully!');
      } catch (emailError) {
        console.error('Error sending reservation email:', emailError);
        toast.error('Booking created, but failed to send reservation email.');
      }

      // Refresh the bookings list and reset the form
      fetchBookings(); // Refresh the bookings list
      setActiveTab('calendar'); // Switch back to calendar view
      // Reset form
      setNewBooking({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        carPlate: '',
        startDate: null,
        endDate: null,
        totalPrice: '',
        status: 'Pending'
      });
    } catch (error) {
      console.error('Error creating booking:', error);
      toast.error(error.response?.data || 'Failed to create booking');
    }
  };

  const filteredBookings = useMemo(() => {
    if (!startDate || !endDate) return bookings;

    return bookings.filter(booking => {
      const bookingStartDate = new Date(booking.startDate);
      const bookingEndDate = new Date(booking.endDate);
      return bookingStartDate >= startDate && bookingEndDate <= endDate;
    });
  }, [bookings, startDate, endDate]);

  const handleFilter = () => {
    console.log('Filtering with date range:', { startDate, endDate });
  };

  const handleClearFilter = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const getStatusStyle = (status) => {
    let backgroundColor;
    let color = 'white';
    
    switch (status?.toLowerCase()) {
      case 'paid with cash':
        backgroundColor = '#4CAF50';
        break;
      case 'pending':
        backgroundColor = '#ff9800';
        break;
      case 'cancelled':
        backgroundColor = '#f44336';
        break;
      default:
        backgroundColor = '#808080';
    }

    return {
      backgroundColor,
      color,
      padding: '4px 8px',
      borderRadius: '4px',
      display: 'inline-block',
      fontSize: '0.875rem'
    };
  };

  const handleSendEmail = async (booking) => {
    const confirmSend = window.confirm(`Are you sure you want to send an email to ${booking.email}?`);
    if (!confirmSend) return;

    if (!booking.email) {
      toast.error('Booking does not have a valid email address.');
      return;
    }

    try {
      setSendingEmailId(booking.id);
      const emailRequest = {
        email: booking.email,
        bookingId: booking.id,
      };

      await axios.post('https://parkado-e7btcwene8c7gadn.northeurope-01.azurewebsites.net/api/bookings/send-reservation-email', emailRequest);
      toast.success(`Email sent successfully to ${booking.email}!`);
    } catch (error) {
      console.error('Error sending email:', error);
      const errorMessage = error.response?.data?.message || 'Failed to send email. Please try again later.';
      toast.error(`Error: ${errorMessage}`);
    } finally {
      setSendingEmailId(null);
    }
  };

  const renderCalendarView = () => (
    <>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', marginBottom: '1rem' }}>
        <DatePicker
          selected={startDate}
          onChange={date => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          placeholderText="Start Date"
          style={{ flex: 1, padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
        />
        <DatePicker
          selected={endDate}
          onChange={date => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          placeholderText="End Date"
          style={{ flex: 1, padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
        />
        {/* <button onClick={handleFilter} style={{ padding: '0.5rem 1rem', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px' }}>Apply Filter</button> */}
        <button onClick={handleClearFilter} style={{ padding: '0.5rem 1rem', backgroundColor: '#f44336', color: 'white', border: 'none', borderRadius: '4px' }}>Anulează Filtrul</button>
      </div>

      <div style={{ overflowX: 'auto' }}>
        <table style={{ minWidth: '100%', borderCollapse: 'collapse', backgroundColor: 'white', border: '1px solid #ddd' }}>
          <thead style={{ backgroundColor: '#f2f2f2' }}>
            <tr>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>ID</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Nume </th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Prenume </th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Email</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Telefon</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Matriculare </th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Data început</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Data sfârșit</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Preț</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Status</th>
              <th style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>Acțiuni</th>
            </tr>
          </thead>
          <tbody>
            {filteredBookings.map((booking) => (
              <tr key={booking.id} style={{ ':hover': { backgroundColor: '#f5f5f5' } }}>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.id}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.firstName || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.lastName || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.email || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.phone || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.carPlate || 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.startDate ? new Date(booking.startDate).toLocaleString() : 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>{booking.endDate ? new Date(booking.endDate).toLocaleString() : 'N/A'}</td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                  {typeof booking.totalPrice === 'number' ? booking.totalPrice.toFixed(2) : 'N/A'}
                </td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                  <span style={getStatusStyle(booking.status)}>
                    {booking.status || 'N/A'}
                  </span>
                </td>
                <td style={{ padding: '0.5rem', borderBottom: '1px solid #ddd' }}>
                  <button
                    onClick={() => handleSendEmail(booking)}
                    style={{
                      padding: '0.5rem 1rem',
                      backgroundColor: '#008CBA',
                      color: 'white',
                      border: 'none',
                      borderRadius: '4px',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                    disabled={sendingEmailId === booking.id}
                  >
                    {sendingEmailId === booking.id ? 'Se trimite...' : 'Trimite Email'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>

  );

  const calculateTotalPrice = (startDate, endDate) => {
    if (!startDate || !endDate) return 0;
    
    // Calculate the total number of days (inclusive)
    const start = new Date(startDate);
    const end = new Date(endDate);
    const totalDays = Math.ceil((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)); 
    
    // Determine the per-day price based on the totalDays
    let perDayPrice = 0;
    if (totalDays >= 23) {
      perDayPrice = 15;
    } else if (totalDays >= 14 && totalDays <= 22) {
      perDayPrice = 20;
    } else if (totalDays >= 7 && totalDays <= 13) {
      perDayPrice = 20;
    } else if (totalDays >= 4 && totalDays <= 6) {
      perDayPrice = 25;
    } else if (totalDays === 3) {
      perDayPrice = 30;
    } else if (totalDays === 2) {
      perDayPrice = 40;
    } else if (totalDays === 1) {
      perDayPrice = 40;
    }
    
    return perDayPrice * totalDays;
  };

  // **Added useEffect to calculate totalPrice when startDate or endDate changes**
  useEffect(() => {
    if (newBooking.startDate && newBooking.endDate) {
      const price = calculateTotalPrice(newBooking.startDate, newBooking.endDate);
      setNewBooking(prevBooking => ({
        ...prevBooking,
        totalPrice: price
      }));
    } else {
      setNewBooking(prevBooking => ({
        ...prevBooking,
        totalPrice: ''
      }));
    }
  }, [newBooking.startDate, newBooking.endDate]);

  const renderAddOrderForm = () => (
    <div style={{ maxWidth: '600px', margin: '0 auto', padding: '20px' }}>
      <form onSubmit={handleCreateBooking} style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '0.5rem' }}>Nume</label>
            <input
              type="text"
              value={newBooking.firstName}
              onChange={(e) => setNewBooking({ ...newBooking, firstName: e.target.value })}
              style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
              required
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '0.5rem' }}>Prenume</label>
            <input
              type="text"
              value={newBooking.lastName}
              onChange={(e) => setNewBooking({ ...newBooking, lastName: e.target.value })}
              style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
              required
            />
          </div>
        </div>

        <div>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>Email</label>
          <input
            type="email"
            value={newBooking.email}
            onChange={(e) => setNewBooking({ ...newBooking, email: e.target.value })}
            style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
            required
          />
        </div>

        <div>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>Telefon</label>
          <input
            type="tel"
            value={newBooking.phone}
            onChange={(e) => setNewBooking({ ...newBooking, phone: e.target.value })}
            style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
            required
          />
        </div>

        <div>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>Nr. Matriculare</label>
          <input
            type="text"
            value={newBooking.carPlate}
            onChange={(e) => setNewBooking({ ...newBooking, carPlate: e.target.value })}
            style={{ width: '100%', padding: '0.5rem', border: '1px solid #ddd', borderRadius: '4px' }}
            required
          />
        </div>

        <div style={{ display: 'flex', gap: '1rem' }}>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '0.5rem' }}>Data Început</label>
            <DatePicker
              selected={newBooking.startDate}
              onChange={date => setNewBooking({ ...newBooking, startDate: date })}
              showTimeSelect
              dateFormat="Pp"
              className="form-control"
              style={{ width: '100%', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
              required
            />
          </div>
          <div style={{ flex: 1 }}>
            <label style={{ display: 'block', marginBottom: '0.5rem' }}>Data Sfârșit</label>
            <DatePicker
              selected={newBooking.endDate}
              onChange={date => setNewBooking({ ...newBooking, endDate: date })}
              showTimeSelect
              dateFormat="Pp"
              minDate={newBooking.startDate}
              className="form-control"
              style={{ width: '100%', padding: '0.5rem', border: '1px solid #ccc', borderRadius: '4px' }}
              required
            />
          </div>
        </div>

        {/* **Modified the Price Field to be Read-Only and Automatically Calculated** */}
        <div>
          <label style={{ display: 'block', marginBottom: '0.5rem' }}>Preț (RON)</label>
          <input
            type="number"
            value={newBooking.totalPrice}
            readOnly // Make the field read-only
            style={{ 
              width: '100%', 
              padding: '0.5rem', 
              border: '1px solid #ddd', 
              borderRadius: '4px',
              backgroundColor: '#f5f5f5' // Optional: to indicate it's read-only
            }}
            placeholder="Prețul va fi calculat automat"
          />
        </div>

        <button
          type="submit"
          style={{
            padding: '0.75rem',
            backgroundColor: '#4CAF50',
            color: 'white',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer',
            marginTop: '1rem'
          }}
        >
          Create Booking
        </button>
      </form>
    </div>
  );

  // **Handle Authentication**
  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (passwordInput === SECRET_KEY) {
      setIsAuthenticated(true);
      setAuthError('');
    } else {
      setAuthError('Parolă incorectă. Vă rugăm să încercați din nou.');
    }
  };

  const renderPasswordScreen = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <div style={{ padding: '2rem', border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 0 10px rgba(0,0,0,0.1)' }}>
        <h2 style={{ textAlign: 'center', marginBottom: '1.5rem' }}>Autentificare</h2>
        <form onSubmit={handlePasswordSubmit}>
          <div style={{ marginBottom: '1rem' }}>
            <label htmlFor="password" style={{ display: 'block', marginBottom: '0.5rem' }}>Parola:</label>
            <input
              type="password"
              id="password"
              value={passwordInput}
              onChange={(e) => setPasswordInput(e.target.value)}
              style={{ width: '100%', padding: '0.5rem', border: authError ? '1px solid red' : '1px solid #ccc', borderRadius: '4px' }}
              required
            />
            {authError && <span style={{ color: 'red', fontSize: '0.875rem' }}>{authError}</span>}
          </div>
          <button
            type="submit"
            style={{
              width: '100%',
              padding: '0.5rem',
              backgroundColor: '#4CAF50',
              color: 'white',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer'
            }}
          >
            Intră
          </button>
        </form>
      </div>
    </div>
  );

  if (!isAuthenticated) {
    return renderPasswordScreen();
  }

  if (isLoading) return <div style={{ textAlign: 'center', marginTop: '2rem' }}>Loading...</div>;
  if (error) return <div style={{ color: 'red', textAlign: 'center', marginTop: '2rem' }}>Error: {error}</div>;

  return (
    <div className="App" style={{ padding: '1rem' }}>
      <h1 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Booking Management</h1>
      
      <ul style={{ 
        display: 'flex', 
        listStyle: 'none', 
        padding: 0, 
        margin: '0 0 2rem 0',
        borderBottom: '1px solid #dee2e6'
      }}>
        <li>
          <button
            onClick={() => setActiveTab('calendar')}
            style={{
              padding: '0.5rem 1rem',
              border: 'none',
              background: 'none',
              borderBottom: activeTab === 'calendar' ? '2px solid #0d6efd' : 'none',
              color: activeTab === 'calendar' ? '#0d6efd' : '#6c757d',
              cursor: 'pointer'
            }}
          >
            Calendar
          </button>
        </li>
        <li>
          <button
            onClick={() => setActiveTab('addOrder')}
            style={{
              padding: '0.5rem 1rem',
              border: 'none',
              background: 'none',
              borderBottom: activeTab === 'addOrder' ? '2px solid #0d6efd' : 'none',
              color: activeTab === 'addOrder' ? '#0d6efd' : '#6c757d',
              cursor: 'pointer'
            }}
          >
            Adaugă Comandă
          </button>
        </li>
      </ul>

      {activeTab === 'calendar' ? renderCalendarView() : renderAddOrderForm()}

      <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />
    </div>
  );
}

export default App;
